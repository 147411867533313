<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      multi-selection="true"
      :filter.sync="search"
      url="floorDisplays"
      @selection-change="handleSelectionChange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tenant.belongUnit')">
          <el-input v-model.trim="search.orgName" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tenant.belongTenant')">
          <el-input v-model.trim="search.tenantName" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
          {{$t("tenant.belongUnit")}}
        </el-button>
        <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
          {{$t("tenant.belongTenant")}}
        </el-button>
      </template>
      <el-table-column prop="no" :label="$t('floorDisplay.no')" width="120" align="center"></el-table-column>
      <el-table-column prop="name" :label="$t('floorDisplay.name')"></el-table-column>
      <el-table-column prop="orgName" :label="$t('elevator.belongCompany')"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="tenantName" :label="$t('user.tenant')"
                       show-overflow-tooltip></el-table-column>
    </vm-table>
    <set-company
      ref="setCompany"
      :multi-select="multiSelect"
      action-type="floor"
      @save-success="getList(-1)"></set-company>
    <set-tenant
      ref="setTenant"
      :multi-select="multiSelect"
      action-type="floor"
      @save-success="getList(-1)"></set-tenant>
  </div>
</template>
<script>
  import SetTenant from "@/views/tenant/SetTenant";
  import SetCompany from "@/views/tenant/SetCompany";

  export default {
    components: {SetTenant, SetCompany},
    data() {
      return {
        loading: false,
        multiSelect: [],
        search: {
          filter: "",
          orgName: "",
          tenantName: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      handleSelectionChange(val){
        this.multiSelect = val;
      },
    },
  };
</script>
<style lang="scss" scoped></style>
