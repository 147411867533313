<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      multi-selection="true"
      :filter.sync="search"
      url="faults"
      @selection-change="handleSelectionChange">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tenant.belongUnit')">
          <el-input v-model.trim="search.orgName" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tenant.belongTenant')">
          <el-input v-model.trim="search.tenantName" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
          {{$t("tenant.belongUnit")}}
        </el-button>
        <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
          {{$t("tenant.belongTenant")}}
        </el-button>
      </template>
      <el-table-column prop="faultNO" :label="$t('faultTemplate.no')" width="120" align="center"></el-table-column>
      <el-table-column prop="desc" :label="$t('faultTemplate.name')"></el-table-column>
      <el-table-column prop="orgName" :label="$t('elevator.belongCompany')"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="tenantName" :label="$t('user.tenant')"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="companyName" :label="$t('elevator.manufactured')"></el-table-column>
      <el-table-column prop="isBuiltIn" :label="$t('faultTemplate.isBuiltIn')" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="$i18n.isCn">
            <el-tag v-if="scope.row.isBuiltIn" type="success">是</el-tag>
            <el-tag v-else type="info">否</el-tag>
          </span>
          <span v-else>
            <el-tag v-if="scope.row.isBuiltIn" type="success">Yes</el-tag>
            <el-tag v-else type="info">No</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="faultMark" label="MARK" align="center" :width="$l('200', '100')"></el-table-column>
      <el-table-column prop="elevCount" :label="$t('company.elevCount')" align="center" :width="$l('200', '100')"></el-table-column>
    </vm-table>
    <set-company
      ref="setCompany"
      :multi-select="multiSelect"
      action-type="fault"
      @save-success="getList(-1)"></set-company>
    <set-tenant
      ref="setTenant"
      :multi-select="multiSelect"
      action-type="fault"
      @save-success="getList(-1)"></set-tenant>
  </div>
</template>
<script>
  import SetTenant from "@/views/tenant/SetTenant";
  import SetCompany from "@/views/tenant/SetCompany";

  export default {
    components: { SetTenant,SetCompany },
    data() {
      return {
        loading: false,
        multiSelect: [],
        search: {
          filter: "",
          orgName: "",
          tenantName: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      handleSelectionChange(val){
        this.multiSelect = val;
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleEditDefault(id) {
        this.$refs.editPage.open(id);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("faultTemplate.faultTemplateNo") + " " + row.faultNO + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.delete("faults/" + row.id).then(res => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
