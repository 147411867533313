<template>
  <el-container>
    <el-aside ref="aside" :width="showAside ? '232px' : '16px'" style="margin-right: 16px;">
      <el-button
        :icon="showAside ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
        class="treeToggle"
        @click="handleCollapse"></el-button>
      <transition name="el-fade-in-linear">
        <vm-tree
          v-show="showAside"
          class="treeSide"
          type="useUnit/false"
          @node-click="onNodeClick"
          @area-click="onAreaClick"
          @node-clear="onNodeClear">
        </vm-tree>
      </transition>
    </el-aside>
    <el-main style="padding:0;width:100vh">
      <vm-table
        ref="vmTable"
        :loading="loading"
        :filter.sync="search"
        multi-selection="true"
        url="users/new"
        @selection-change="handleSelectionChange">
        <template slot="toolbar">
          <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
            {{$t('tenant.belongUnit')}}
          </el-button>
          <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
            {{$t('tenant.belongTenant')}}
          </el-button>
        </template>
        <template slot="adSearch">
          <vm-search :label="$t('common.keywordSearch')">
            <el-input v-model.trim="search.keyword" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$t('user.type')">
            <el-select v-model="search.userType" :placeholder="$t('common.pleaseSelect')" clearable>
              <el-option
                v-for="item in userTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$t('tenant.belongUnit')">
            <el-input v-model.trim="search.orgName" clearable></el-input>
          </vm-search>
          <vm-search :label="$t('tenant.belongTenant')">
            <el-input v-model.trim="search.tenantName" clearable></el-input>
          </vm-search>
        </template>
        <el-table-column prop="tenantName" :label="$t('tenant.tenant')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="orgName" :label="$t('tenant.belongUnit')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="username" :label="$t('user.username')" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('user.type')" width="140">
          <template v-slot="scope">
            <el-tag :type="scope.row.type === 0 || scope.row.type === 1 || scope.row.type === 3 ? 'success' : 'info'">
              {{ $t("user.types." + scope.row.type) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('user.name')" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" :label="$t('user.phone')" width="110" align="center"></el-table-column>
        <el-table-column prop="creator" :label="$t('gatewayVersion.creator')" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" :label="$t('msgPushRecord.createTime')" width="140" align="center"></el-table-column>
        <el-table-column prop="loginTime" :label="$t('user.lastLoginTime')" width="140" align="center"></el-table-column>
        <el-table-column fixed="right" :label="$t('common.operation')" width="150" align="center">
          <template v-slot="scope">
            <el-button type="text" @click="login(scope.row)">
              登录
            </el-button>
            <el-button type="text" @click="handleSubmit(scope.row)">
              生成租户
            </el-button>
            <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </vm-table>
      <set-company
        ref="setCompany"
        :multi-select="multiSelect"
        action-type="user"
        @save-success="getList(-1)"></set-company>
      <set-tenant ref="setTenant" :multi-select="multiSelect" action-type="user" @save-success="getList(-1)"></set-tenant>
    </el-main>
  </el-container>
</template>
<script>
  import SetTenant from "@/views/tenant/SetTenant";
  import SetCompany from "@/views/tenant/SetCompany";
  import vmTree from "@/components/VmTree";
  import { typeToArray } from "@/util/constant";
  import auth from "@/util/auth";

  export default {
    components: { vmTree, SetTenant, SetCompany },
    data() {
      return {
        editAuth: this.$auth(2),
        loading: false,
        multiSelect: [],
        search: {},
        showAside: false,
        userTypeOptions: typeToArray(this.$t("user.types")),
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      handleSelectionChange(val) {
        this.multiSelect = val;
      },
      login(row) {
        let params = {
          username: row.username,
          password: row.password,
        };
        this.$api.getData("account/tenantLogin", params).then((res) => {
          auth.logout();
          auth.login(res.data);
          window.location.href = "/";
        }).catch(error => {
          this.$message.error(this.$t("tenant.loginFailed"));
        });
      },
      getList(pageNum) {
        this.loading = true;
        this.$refs.vmTable.getList(pageNum);
        this.loading = false;
      },
      handleCollapse() {
        this.showAside = !this.showAside;
        if (this.showAside) {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 20px");
        } else {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 0px");
        }
      },
      onNodeClick(data) {
        this.search.companyId = data.id;
        this.getList(1);
      },
      onAreaClick(data) {
        this.search.companyId = data.id;
        this.getList(1);
      },
      onNodeClear() {
        this.search.companyId = "";
        this.getList(1);
      },
      handleSubmit(row) {
        let params = {
          username: row.username,
        };
        this.$api.getData("tenant/gen", params).then((res) => {
          this.loginLoading = false;
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.loginLoading = false;
        });
      },
      handleDelete(row) {
        this.$confirm(this.$t("user.username") + " " + row.username + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("users", row.id).then(() => {
            this.getList(1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.vm-main .vm-main-page .vm-content .vm-page {
  padding: 17px 22px 17px 0;
}

::v-deep .el-tree {
  overflow-x: auto;
}
</style>
