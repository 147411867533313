<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    title="Flv地址"
    width="500px"
    top="3vh">
    <el-form
      ref="formValidate"
      class="form"
      label-width="80px">
      <el-form-item label="Flv地址">
        <el-input v-model="flvUrl"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "确定")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        flvUrl: "",
      };
    },
    methods: {
      open(url) {
        this.dialogVisible = true;
        if (url) {
          this.flvUrl = url;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
