<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('tenant.belongUnit')"
    width="500px"
    top="3vh">
    <div>
      <el-form
        ref="form"
        :model="orgId"
        label-width="90px"
        style="margin-top: 20px">
        <div>
          <el-form-item :label="$t('tenant.belongUnit')" prop="companyId">
            <el-input v-model="orgId.companyName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button slot="append" icon="el-icon-search" @click="$refs.selectOrgCom.open()"></el-button>
            </el-input>
            <company-select ref="selectOrgCom" @select="onSelectOrgCom"></company-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button type="primary" size="small" :loading="submitLoading" @click="saveOrgId">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

  import CompanySelect from "@/views/tenant/CompanySelect";

  export default {
    components: {CompanySelect},
    props: {
      multiSelect: {
        type: Array,
      },
      actionType: {
        type: String,
      },
    },
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        orgId: {
          companyId: 0,
          companyName: "",
        },
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      saveOrgId() {
        console.log(this.multiSelect);
        let userIds = [];
        let elevatorIds = [];
        let companyIds = [];
        let useUnitIds = [];
        let tplinkIds = [];
        let screenIds = [];
        let faultIds = [];
        let floorIds = [];

        if (this.actionType === "user") {
          for (let item of this.multiSelect) {
            userIds.push(item.id);
          }
        }
        if (this.actionType === "elevator") {
          for (let item of this.multiSelect) {
            elevatorIds.push(item.id);
          }
        }
        if (this.actionType === "fault") {
          for (let item of this.multiSelect) {
            faultIds.push(item.id);
          }
        }
        if (this.actionType === "floor") {
          for (let item of this.multiSelect) {
            floorIds.push(item.id);
          }
        }
        if (this.actionType === "company") {
          for (let item of this.multiSelect) {
            companyIds.push(item.id);
          }
        }
        if (this.actionType === "useUnit") {
          for (let item of this.multiSelect) {
            useUnitIds.push(item.id);
          }
        }
        if (this.actionType === "tplink") {
          for (let item of this.multiSelect) {
            tplinkIds.push(item.id);
          }
        }
        if (this.actionType === "screen") {
          for (let item of this.multiSelect) {
            screenIds.push(item.id);
          }
        }
        if (userIds.length === 0 && faultIds.length===0 && floorIds.length===0 && elevatorIds.length === 0 && companyIds.length === 0 && useUnitIds.length === 0 && tplinkIds.length === 0 && screenIds.length === 0) {
          this.$message.error("未选中任何设置项");
          return;
        }
        if (this.orgId.companyId === 0) {
          this.$message.error("公司不能为空");
          return;
        }
        let params = {
          userIds: userIds,
          elevatorIds: elevatorIds,
          companyIds: companyIds,
          useUnitIds: useUnitIds,
          tplinkIds: tplinkIds,
          screenIds: screenIds,
          faultIds: faultIds,
          floorIds:floorIds,
          companyId: this.orgId.companyId,
        };
        this.$api.save("tenant/batchUpdate", params).then(res => {
          this.submitLoading = false;
          this.$emit("save-success");
          this.multiSelect = [];
          this.orgId = {
            companyId: 0,
            companyName: "",
          };
          this.dialogVisible = false;
          this.$message.success(this.$t("common.tip.saveSuccess"));
          this.$emit("save-success");
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error);
        });
      },
      onSelectOrgCom(row) {
        this.orgId.companyId = row.id;
        this.orgId.companyName = row.name;
      },
      clearOrg() {
        this.orgId = {
          companyId: 0,
          companyName: "",
        };
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
