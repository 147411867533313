<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    title="所属平台"
    width="500px"
    top="3vh">
    <div>
      <el-form
        ref="form"
        :model="ztVersion"
        label-width="90px"
        style="margin-top: 20px">
        <div>
          <el-form-item label="所属平台">
            <el-input v-model="ztVersion.platformShortName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button slot="append" icon="el-icon-search" @click="$refs.platFormSelect.open()"></el-button>
            </el-input>
            <platform-select ref="platFormSelect" @select="onSelectTenantCom"></platform-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button type="primary" size="small" :loading="submitLoading" @click="savePlatformId">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

  import PlatformSelect from "@/views/tenant/PlatformSelect";

  export default {
    components: {PlatformSelect},
    props: {
      multiSelect: {
        type: Array,
      },
      actionType: {
        type: String,
      },
    },
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        ztVersion: {
          platformShortName: "",
        },
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      savePlatformId() {
        let tplinkIds = [];

        if (this.actionType === "tplink") {
          for (let item of this.multiSelect) {
            tplinkIds.push(item.id);
          }
        }
        if (tplinkIds.length === 0) {
          this.$message.error(this.$t("tenant.settingsSelected"));
          return;
        }
        let params = {
          tplinkIds: tplinkIds,
          platformName: this.ztVersion.platformShortName,
        };
        this.$api.save("tpLink/batchUpdate", params).then(res => {
          this.submitLoading = false;
          this.$emit("save-success");
          this.multiSelect = [];
          this.ztVersion = {
            platformShortName: "",
          };
          this.dialogVisible = false;
          this.$message.success(this.$t("common.tip.saveSuccess"));
          this.$emit("save-success");
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error);
        });
      },
      onSelectTenantCom(row) {
        this.ztVersion.id = row.id;
        this.ztVersion.platformShortName = row.platformShortName;
      },
      clearOrg() {
        this.ztVersion = {
          id: 0,
          platformShortName: "",
        };
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
