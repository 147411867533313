<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="model.id?$l('elevatorNetApply.editElevatorNetApply','编辑'):$l('elevatorNetApply.addElevatorNetApply','新增')"
    width="1000px"
    top="3vh"
    @close="$reset('form')">
    <div class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="100px"
        :model="model"
        :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item :label="$l('tpLink.deviceName','VMSID')" prop="devId">
            <el-input v-model="model.devId" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$l('elevatorLcd.lcdId','MAC地址')" prop="ethernet">
            <el-input v-model="model.ethernet" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$l('tpLink.deviceName','监控设备名称')" prop="deviceName">
            <el-input v-model="model.deviceName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$l('tpLink.deviceType','设备类型')" prop="deviceType">
            <el-input v-model="model.deviceType" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$l('tpLink.deviceModel','设备型号')" prop="deviceModel">
            <el-input v-model="model.deviceModel" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div></div>
        </div>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        model: {
          id: 0,
          devId: "",
          ethernet: "",
          deviceType: "",
          deviceName: "",
          deviceModel: "",
        },
        ruleValidate: {
          ethernet: [
            {required: true, min: 17, max: 17, message: this.$t("elevatorLcd.tip.mac"), trigger: "blur"},
          ],
          devId: [{
            required: true,
            message: this.$t("common.tip.notNull"),
            trigger: "blur",
          }],
        },
      };
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("tpLink/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
          });
      },
      handleSubmit() {
        this.$refs.formValidate.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("tpLink", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
