<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="elevatorLcds"
      multi-selection="true"
      @selection-change="handleSelectionChange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('elevatorLcd.onLineStatus', '在线状态')">
          <el-select
            v-model.trim="search.onLineStatus"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('120px', '100px') }"
            clearable>
            <el-option
              v-for="item in onLineStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
          {{$t('tenant.belongUnit')}}
        </el-button>
        <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
          {{$t("tenant.belongTenant")}}
        </el-button>
      </template>
      <el-table-column prop="tenantName" :label="$t('tenant.tenant')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orgName" :label="$t('tenant.belongUnit')" show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="elevatorNo"
        :label="$l('elevator.elevatorNo', '电梯编号')"
        width="120"
        align="center"></el-table-column>
      <el-table-column
        prop="elevatorName"
        show-overflow-tooltip
        :label="$l('elevatorLcd.elevatorName', '电梯名称')"></el-table-column>
      <el-table-column
        prop="useUnitName"
        show-overflow-tooltip
        :label="$t('elevatorLcd.useUnitName')"></el-table-column>
      <el-table-column
        prop="terminalID"
        :label="$t('deviceGateway.dtuCode')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="heartTime" :label="$t('elevatorLcd.onLineStatus')" width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.onlineStatus === 1" type="success">
            {{$t("workOrder.onLine")}}
          </el-tag>
          <el-tag v-else type="info">{{$t("workOrder.offLine")}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="cardName" :label="$l('elevatorLcd.currentProgram', '当前节目单')"
                       show-overflow-tooltip></el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" align="center" width="310">
        <template slot-scope="scope">
          <el-button v-if="scope.row.terminalID && scope.row.onlineStatus === 1" type="text"
                     @click="mqtt1(scope.row.terminalID)">升级
          </el-button>
          <el-button v-if="scope.row.terminalID && scope.row.onlineStatus === 1" type="text"
                     @click="mqtt2(scope.row.terminalID)">楼层
          </el-button>
          <el-button v-if="scope.row.terminalID && scope.row.onlineStatus === 1" type="text"
                     @click="mqtt3(scope.row.terminalID)">节目单
          </el-button>
          <el-button v-if="scope.row.terminalID && scope.row.onlineStatus === 1" type="text"
                     @click="mqtt4(scope.row.terminalID)">呼叫
          </el-button>
          <el-button v-if="scope.row.terminalID && scope.row.onlineStatus === 1" type="text"
                     @click="mqtt5(scope.row.terminalID)">推流
          </el-button>
          <el-button v-if="scope.row.terminalID && scope.row.onlineStatus === 1" type="text"
                     @click="handleVideo(scope.row.terminalID)">视频
          </el-button>
          <el-button v-if="scope.row.terminalID && scope.row.onlineStatus === 1" type="text"
                     @click="handleFlv(scope.row.terminalID)">flv
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{$t("common.delete")}}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
    <set-company
      ref="setCompany"
      :multi-select="multiSelect"
      action-type="screen"
      @save-success="getList(-1)"></set-company>
    <set-tenant
      ref="setTenant"
      :multi-select="multiSelect"
      action-type="screen"
      @save-success="getList(-1)"></set-tenant>
  </div>
</template>
<script>
  import EditDialog from "./ElevatorLcdEdit.vue";
  import SetTenant from "@/views/tenant/SetTenant";
  import SetCompany from "@/views/tenant/SetCompany";

  const moduleName = "elevatorLcds";

  export default {
    components: {EditDialog, SetCompany, SetTenant},
    data() {
      return {
        editAuth: this.$auth(74),
        loading: false,
        multiSelect: [],
        search: {
          isBind: 1,
          filter: "",
          onLineStatus: "",
        },
        onLineStateOptions: [
          {value: 0, label: this.$l("workOrder.offLine", "离线")},
          {value: 1, label: this.$l("workOrder.onLine", "在线")},
        ],
      };
    },
    mounted() {
      this.getList(1);
      // setInterval(() => this.getList(1), 10000);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      handleSelectionChange(val) {
        this.multiSelect = val;
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      mqtt1(dtuCode) {
        this.$http.get("elevatorLcds/mqtt/" + dtuCode + "/" + 1).then(res => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        });
      },
      mqtt2(dtuCode) {
        this.$http.get("elevatorLcds/mqtt/" + dtuCode + "/" + 2).then(res => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        });
      },
      mqtt3(dtuCode) {
        this.$http.get("elevatorLcds/mqtt/" + dtuCode + "/" + 3).then(res => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        });
      },
      mqtt4(dtuCode) {
        this.$http.get("elevatorLcds/mqtt/" + dtuCode + "/" + 4).then(res => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        });
      },
      mqtt5(dtuCode) {
        this.$http.get("elevatorLcds/mqtt/" + dtuCode + "/" + 5).then(res => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        });
      },
      handleFlv(dtuCode) {
        this.loading = true;
        this.$http.get("elevatorLcds/getFlvUrl/" + dtuCode).then(res => {
          this.loading = false;
          if (res.data.data) {
            alert(res.data.data);
          }
        }).catch((error) => {
          this.loading = false;
        });
      },
      handleVideo(dtuCode) {
        this.loading = true;
        this.$http.get("elevatorLcds/getVideoUrl/" + dtuCode).then(res => {
          this.loading = false;
          if (res.data.data) {
            window.open(res.data.data);
          }
        }).catch((error) => {
          this.loading = false;
        });
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("deviceGateway.dtuCode") + " " + row.terminalID + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById(moduleName, row.id).then(res => {
            this.getList(1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
