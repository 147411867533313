<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="tenant.id > 0 ? '编辑' : '新增'"
    width="500px"
    top="3vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      label-width="70px"
      :model="tenant"
      :rules="ruleValidate">
      <div>
        <el-form-item label="租户名称" prop="name" :rules="$rule.notNull">
          <el-input
            v-model.trim="tenant.name"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item label="租户状态" prop="status" :rules="$rule.notNull">
          <el-select
            v-model="tenant.status"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="初始公司" prop="companyName" :rules="tenant.id === 0 ? $rule.notNull : null">
          <el-autocomplete
            v-model="tenant.companyName"
            class="inline-input"
            :fetch-suggestions="companyQuerySearch"
            :placeholder="$t('common.pleaseEnter')"
            :trigger-on-focus="false"
            style="width: 100%"
            :disabled="tenant.id > 0"
          >
          </el-autocomplete>
        </el-form-item>
        <el-form-item :label="$t('company.type')" prop="companyType">
          <el-select v-model="tenant.companyType" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
            <el-option
              v-for="item in companyTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人" prop="contact" :rules="$rule.notNull">
          <el-input
            v-model.trim="tenant.contact"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone" :rules="$rule.notNull">
          <el-input
            v-model.trim="tenant.phone"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model.trim="tenant.email"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="tenant.username"
            :disabled="tenant.id > 0"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item v-if="tenant.id === 0" label="密码" prop="password">
          <el-input
            v-model="tenant.password"
            type="text"
            :class="isShowPassword ? '' : 'displayPass'"
            :placeholder="$t('common.pleaseEnter')"
            autocomplete="new-password"></el-input>
          <i
            class="el-icon-view"
            :class="{ icon: isShowPassword }"
            style="position: absolute;right: 8px;top: 8px;color: #e6e6e6"
            @click="show"></i>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {companyType, companyType_en, typeToArray} from "@/util/constant";

  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        isShowPassword: false,
        tenant: {
          id: 0,
          name: "",
          status: "",
          username: "",
          password: "",
          startTime: "",
          endTime: "",
          contact: "",
          phone: "",
          email: "",
          companyName: "",
        },
        companyList: [],
        companyTypes: [],
        statusList: [
          {value: 1, label: "冻结"},
          {value: 0, label: "正常"},
        ],
      };
    },
    computed: {
      ruleValidate() {
        return {
          username: {
            required: this.tenant.id === 0,
            pattern: /^[a-zA-Z0-9_@.]{4,25}$/,
            message: this.$t("user.userNameRule"),
            trigger: "blur",
          },
          password: {
            required: this.tenant.id === 0,
            pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
            message: this.$t("user.passwordRule"),
            trigger: "blur",
          },
          companyType: [
            {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"}
          ],
        };
      },
    },
    mounted() {
      this.companyTypes = typeToArray(this.$t("lang") === "en" ? companyType_en : companyType);
    },
    methods: {
      open(id) {
        this.tenant.id = id;
        this.dialogVisible = true;
        if (this.tenant.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("tenant/" + this.tenant.id)
          .then(({data}) => {
            this.$assign(this.tenant, data);
          });
      },
      show() {
        this.isShowPassword = !this.isShowPassword;
      },
      handleSubmit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("tenant", this.tenant).then(() => {
              this.dialogVisible = false;
              this.submitLoading = false;
              this.$emit("save-success");
              this.$message.success("保存成功");
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error("保存失败");
            });
          }
        });
      },
      companyQuerySearch(queryString, cb) {
        if (this.companyList && this.companyList.length) {
          cb(this.filterQueryString(this.companyList, queryString));
        } else {
          this.$http.get("companies/list")
            .then(res => {
              this.companyList = res.data.map(item => {
                return {
                  value: item.name,
                };
              });
              cb(this.filterQueryString(this.companyList, queryString));
            });
        }
      },
      filterQueryString(list, queryString) {
        return list.filter(item => {
          return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.displayPass {
  //将文本替换为指定的字符
  -webkit-text-security: disc;
}
</style>
