<template>
  <div>
    <div>
      <el-tabs v-model="activeName" tab-position="top">
        <el-tab-pane :label="$t('route.elevator')" name="first">
          <elevator-list></elevator-list>
        </el-tab-pane>
        <el-tab-pane :label="$t('tenant.unitInformation')" name="second">
          <company-list></company-list>
        </el-tab-pane>
        <el-tab-pane :label="$t('elevator.faultTemplate')" name="third">
          <fault-list></fault-list>
        </el-tab-pane>
        <el-tab-pane :label="$t('floorDisplay.floorDisplay')" name="forth">
          <floor-display-list></floor-display-list>
        </el-tab-pane>
        <el-tab-pane :label="$t('tenant.user')" name="fifth">
          <user-list></user-list>
        </el-tab-pane>
        <el-tab-pane :label="$t('tenant.tplinkCamera')" name="six">
          <t-p-link-video-list></t-p-link-video-list>
        </el-tab-pane>
        <el-tab-pane :label="$t('tenant.smartScreen')" name="seven">
          <elevator-lcd-list></elevator-lcd-list>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import ElevatorList from "@/views/tenant/ElevatorList";
  import CompanyList from "@/views/tenant/CompanyList";
  import UserList from "@/views/tenant/UserList";
  import auth from "../../util/auth";
  import TPLinkVideoList from "@/views/tenant/tplink/TPLinkVideoList";
  import ElevatorLcdList from "@/views/tenant/smartScreen/ElevatorLcdList";
  import FaultList from "@/views/tenant/FaultList";
  import FloorDisplayList from "@/views/tenant/FloorDisplayList";

  export default {
    components: {ElevatorLcdList,FloorDisplayList,FaultList, TPLinkVideoList, UserList, CompanyList, ElevatorList},
    data() {
      return {
        activeName: "first",
        showOrgId: auth.getUsername() === "superAdmin",
        submitLoadingOrg: false,
        type: 1,
        elevatorList: [],
        orgId: {
          companyId: 0,
          companyName: "",
        },
        tenant: {
          tenantId: 0,
          tenantName: "",
        },
      };
    },
    mounted() {

    },
    methods: {
      clearOrg() {
        this.orgId = {
          companyId: 0,
          companyName: "",
        };
        this.tenant = {
          tenantId: 0,
          tenantName: "",
        };
      },
    },
  };
</script>

<style scoped>
</style>
