<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      multi-selection="true"
      url="elevators/page"
      @selection-change="handleSelectionChange">
      <template slot="toolbar">
        <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
          {{$t("tenant.belongUnit")}}
        </el-button>
        <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
          {{$t("tenant.belongTenant")}}
        </el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('elevator.elevatorType')">
          <el-select
            v-model="search.productTypeId"
            :style="{ width: $l('250px', '150px') }"
            :placeholder="$t('elevator.elevatorType')"
            clearable>
            <el-option
              v-for="item in productTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.onlineStatus')">
          <el-select
            v-model="search.onlineStatus"
            :placeholder="$t('elevator.onlineStatus')"
            :style="{ width: $l('150px', '100px') }"
            clearable>
            <el-option
              v-for="item in onlineStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.activatedPartsStatus')">
          <el-select
            v-model="search.hasActivatedParts"
            :placeholder="$t('elevator.activatedPartsStatus')"
            :style="{ width: $l('200px', '100px') }"
            clearable>
            <el-option
              v-for="item in activatedPartsStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.joinStatus')">
          <el-select
            v-model="search.inNetState"
            :placeholder="$t('elevator.joinStatus')"
            :style="{ width: $l('150px', '100px') }"
            clearable>
            <el-option
              v-for="item in inNetStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')">
          <el-select
            v-model="search.integrationKey"
            :placeholder="$t('elevator.accessPlatform')"
            :style="{ width: $l('160px', '150px') }"
            clearable>
            <el-option
              v-for="item in integrationList"
              :key="item.integrationKey"
              :label="item.name"
              :value="item.integrationKey"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('tenant.elevatorId')">
          <el-input v-model.trim="search.id" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tenant.belongUnit')">
          <el-input v-model.trim="search.orgName" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tenant.belongTenant')">
          <el-input v-model.trim="search.tenantName" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="no" :label="$t('elevator.no')" width="120" align="center">
        <template v-slot="scope">
          <router-link :to="'/elevator/' + scope.row.id" target="_blank">{{scope.row.no}}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="tenantName" :label="$t('tenant.tenant')" show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="orgName"
        :label="$t('tenant.belongUnit')"
        show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="regNo"
        :label="$t('elevator.registerCode')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="id" label="id" align="center" width="90"></el-table-column>
      <el-table-column prop="name" :label="$t('elevator.name')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" align="center" width="120"></el-table-column>
      <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="maintComName" :label="$t('elevator.maintenanced')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createComName" :label="$t('elevatorNetApply.manufactured')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevatorPropertyComName" :label="$t('elevatorNetApply.propertyCom')" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" width="100" align="center">
        <template v-slot="scope">
          <span v-for="(item, index) in scope.row.elevatorIntegrationVos" :key="index">
            <span>{{
              item.integrationKeyName + (index === scope.row.elevatorIntegrationVos.length - 1 ? "" : ",")
            }}</span>
          </span>
        </template>
      </el-table-column>
    </vm-table>
    <set-company
      ref="setCompany"
      :multi-select="multiSelect"
      action-type="elevator"
      @save-success="getList(-1)"></set-company>
    <set-tenant
      ref="setTenant"
      :multi-select="multiSelect"
      action-type="elevator"
      @save-success="getList(-1)"></set-tenant>
  </div>
</template>
<script>
  import SetTenant from "@/views/tenant/SetTenant";
  import SetCompany from "@/views/tenant/SetCompany";

  const moduleName = "elevators";
  const moduleName_productType = "elevatorProductTypes";

  export default {
    components: {SetCompany, SetTenant},
    data() {
      return {
        search: {
          filter: "",
          productTypeId: "",
          integrationKey: "",
          maintComId: "",
          quantityOrganId: "",
          createComId: "",
          installComId: "",
          remakeComId: "",
          useUnitId: "",
          onlineStatus: "",
          inNetState: "",
          hasActivatedParts: "",
          districtId: "",
          id: "",
          orgName: "",
          tenantName: "",
        },
        loading: false,
        multipleDisabled: false,
        multiSelect: [],
        productTypeOptions: [],
        inNetStateOptions: [
          {value: "false", label: this.$t("elevator.notJoined")},
          {value: "true", label: this.$t("elevator.joined")},
        ],
        activatedPartsStatus: [
          {value: "false", label: this.$t("elevator.notActivated")},
          {value: "true", label: this.$t("elevator.activated")},
        ],
        onlineStatusOptions: [
          {value: 0, label: this.$t("device.offline")},
          {value: 1, label: this.$t("device.online")},
        ],
        integrationList: [],
      };
    },
    mounted() {
      this.getList(1);
      this.getProductTypeOptions();
      this.getIntegrationList();
    },
    methods: {
      getList(pageIndex) {
        this.loading = true;
        this.$refs.vmTable.getList(pageIndex);
        this.loading = false;
      },
      getProductTypeOptions() {
        this.$api.getList(moduleName_productType).then(response => {
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName};
            } else {
              item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn};
            }
            this.productTypeOptions.push(item);
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleSelectionChange(val) {
        this.multiSelect = val;
      },
      getIntegrationList() {
        this.$http.get("integrations/list").then(({data}) => {
          this.integrationList = data;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.vm-main .vm-main-page .vm-content .vm-page {
  padding: 17px 22px 17px 0;
}
</style>
