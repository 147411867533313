<template>
  <el-dialog class="select-dialog" title="选择平台" :visible.sync="dialogVisible" width="80%" top="2vh" append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="ZTVersion">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px"
            clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="companyName" label="公司" show-overflow-tooltip></el-table-column>
      <el-table-column prop="platformShortName" label="平台简称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fkey" label="唯一标识" width="100" align="center"></el-table-column>
      <el-table-column prop="serverUrl" label="服务器地址" show-overflow-tooltip></el-table-column>
      <el-table-column prop="monitorHost" label="监控服务器地址" show-overflow-tooltip></el-table-column>
      <el-table-column prop="monitorPort" label="监控服务器端口号" width="140" align="center"></el-table-column>
      <el-table-column prop="dtuServerHost" label="DTU服务器地址" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dtuServerPort" label="DTU服务器端口号" width="140" align="center"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
import { companyType } from "@/util/constant";
import { companyType_en } from "@/util/constant";

export default {
  data() {
    return {
      dialogVisible: false,
      loading: true,
      extra: null,
      search: {
        filter: "",
      },
    };
  },
  methods: {
    open(type, extra, filter) {
      if (type) {
        this.search.type = type;
      }
      this.dialogVisible = true;
      this.extra = extra;
      this.$nextTick(() => {
        if (filter) {
          this.search.filter = filter;
        }
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    getTypeName(type) {
      if (this.$i18n.isCn) {
        return companyType[type];
      } else {
        return companyType_en[type];
      }
    },
    handleSelect(row) {
      this.$emit("select", row, this.extra);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
