<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      multi-selection="true"
      url="companies"
      @selection-change="handleSelectionChange">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('company.type')">
          <el-select v-model.trim="search.type" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option
              v-for="item in companyTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('tenant.belongUnit')">
          <el-input v-model.trim="search.orgName" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tenant.belongTenant')">
          <el-input v-model.trim="search.tenantName" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
          {{$t('tenant.belongUnit')}}
        </el-button>
        <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
          {{$t('tenant.belongTenant')}}
        </el-button>
      </template>
      <el-table-column prop="tenantName" :label="$t('tenant.tenant')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orgName" :label="$t('tenant.belongUnit')" show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="elevCount"
        :label="$t('company.elevCount')"
        align="center"
        width="100"></el-table-column>
      <el-table-column
        prop="userCount"
        :label="$t('company.userCount')"
        align="center"
        width="100"></el-table-column>
      <el-table-column prop="name" :label="$t('company.name')" min-width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="no" :label="$t('company.no')" width="120" align="center"></el-table-column>
      <el-table-column prop="id" label="id" width="90" align="center"></el-table-column>
      <el-table-column :label="$t('company.type')" :width="$l('company.width', '100')" align="center">
        <template slot-scope="scope">
          {{ getTypeName(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="creator" :label="$t('gatewayVersion.creator')" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="phone" :label="$t('company.phone')" width="150"></el-table-column>
      <el-table-column prop="createTime" :label="$t('msgPushRecord.createTime')" width="140" align="center"></el-table-column>
      <el-table-column fixed="right" :label="$t('common.operation')" width="80" align="center">
        <template slot-scope="scope">
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <set-company
      ref="setCompany"
      :multi-select="multiSelect"
      action-type="company"
      @save-success="getList(-1)"></set-company>
    <set-tenant
      ref="setTenant"
      :multi-select="multiSelect"
      action-type="company"
      @save-success="getList(-1)"></set-tenant>
  </div>
</template>
<script>
  import SetTenant from "@/views/tenant/SetTenant";
  import SetCompany from "@/views/tenant/SetCompany";
  import { companyType, companyType_en } from "../../util/constant";

  const moduleName = "companies";

  export default {
    components: { SetCompany, SetTenant },
    data() {
      return {
        loading: false,
        multiSelect: [],
        search: {
          filter: "",
          type: "",
          orgName: "",
          tenantName: "",
        },
        companyTypeList: [
          {value: 1, label: this.$t("company.manufacturerCompany")},
          {value: 2, label: this.$t("company.maintenanceCompany")},
          {value: 3, label: this.$t("company.installationCompany")},
          {value: 5, label: this.$t("company.propertyCompany")},
          {value: 6, label: this.$t("company.modificationCompany")},
          {value: 8, label: this.$t("company.useUnit")},
          {value: 9, label: this.$t("elevator.agent")},
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      handleSelectionChange(val) {
        this.multiSelect = val;
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("company.no") + " " + row.no + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("companies", row.id).then(res => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
      getTypeName(type) {
        if (this.$i18n.isCn) {
          return companyType[type];
        } else {
          return companyType_en[type];
        }
      },
    },
  };
</script>
<style lang="scss" scoped></style>
