<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$t('tenant.dataBelong')" name="first">
      <batch-modify></batch-modify>
    </el-tab-pane>
    <el-tab-pane :label="$t('tenant.tenantManage')" name="second">
      <tenant-list></tenant-list>
    </el-tab-pane>
    <el-tab-pane label="私有平台" name="third">
      <z-t-version-list></z-t-version-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import TenantList from "@/views/tenant/TenantList";
  import BatchModify from "@/views/tenant/BatchModify";
  import ZTVersionList from "@/views/tenant/ztVersion/ZTVersionList";

  export default {
    components: {ZTVersionList, BatchModify, TenantList},
    data() {
      return {
        activeName: "first",
      };
    },
    mounted() {

    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
